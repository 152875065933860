.sidebar--container {
  position: fixed;
  height: 100%;
  width: 15rem;
  top: 5rem;
  background-color: var(--bg-color);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sidebar--container img {
  padding: 0px 1rem 2px 1rem;
}
.tab {
  width: 80%;
  height: 2.6rem;
  padding: 0.2rem;
  display: flex;
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
  color: var(--font-color);
  font-weight: 700;
}

.active-tab {
  background-color: var(--highlight-color);
  color: var(--font-color-sec);
}

@media screen and (max-width: 950px) {
  .trans-on {
    left: 0;
    transition: left 0.5s ease-out;
  }

  .trans-off {
    left: -30.875rem;
    transition: left 0.5s ease-out;
  }

  .sidebar--container {
    /* width: 0rem; */
    /* position: relative; */

    z-index: 2;
  }
  .sidebar--container {
    top: 7rem;
  }
}
