.playlist-container {
  background-color: var(--bg-color);
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-start;
}
.playlist-names {
  width: 35%;
  display: flex;
  flex-direction: column;
  margin: 1rem 1rem 0 0;
}

.playlist-videos {
  padding: 2rem 0 2rem 0;
}

#video-list-container {
  height: 100%;
  right: 0;
  overflow-y: scroll;
}

.list-name {
  cursor: pointer;
  height: 5rem;
  width: 80%;
  background-color: rgb(226, 226, 226);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1.5rem 2rem 0 2rem;
  border-radius: 10px;
  font-weight: 700;
  box-shadow: 0px 1px 21px -2px var(--highlight-color);
}

.list-name:hover {
  background-color: rgb(187, 192, 192);
}

.selected-playlist {
  background-color: var(--highlight-color);
}

.single-video {
  height: 5.2rem;
  display: flex;
  /* margin-bottom: 1rem; */
  margin: 0.5rem 0 0.8rem 0;
  width: 90%;
  /* border: 1px solid red; */
  border-radius: 6px;
  overflow: hidden;
  box-shadow: 0px 1px 21px -2px var(--highlight-color);
}

.single-video:hover {
  background-color: var(--highlight-color);
}
.video-text {
  margin-left: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
