.cards-container {
  /* position: absolute; */
  /* display: grid;
  grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr)); */
  /* display: flex;
  flex-wrap: wrap; */
  /* justify-content: space-around; */
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  grid-auto-rows: minmax(auto, auto);
  background-color: var(--bg-color);
}

.video-card {
  /* display: grid; */
  /* justify-content: space-evenly; */
  /* height: 20rem; */
  /* width: 18rem; */
  margin: 2rem;
  /* background-color: rgb(226, 226, 226); */
  background-color: var(--bg-color);
  box-shadow: 0px 1px 21px -2px var(--highlight-color);
  border-radius: 6px;
  overflow: hidden;
  /* border: 1px solid var(--highlight-color); */
}
.thumbnail {
  width: 100%;
  cursor: pointer;
}
.video-description {
  margin: 0.5rem;
}

.video-date {
  /* position: relative; */
  font-size: 0.8rem;
  color: var(--font-color-date);
  margin-top: 1rem;
}

.delete-icon img {
  margin-top: 0.5rem;
  padding: 5px;
}

.delete-icon img:hover {
  background-color: var(--highlight-color);
  border-radius: 50%;
}

#sort-buttons {
  margin: 0 2rem 0 2rem;
  display: flex;
  justify-content: flex-end;
}

.sort-button {
  margin-left: 1rem;
  width: 8rem;
  height: 2rem;
  border-radius: 6px;
  border: 0;
  font: inherit;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  box-shadow: 0px 1px 21px -2px var(--highlight-color);
  background-color: var(--bg-color);
  color: var(--font-color);
}

.sort-button-selected {
  background-color: var(--highlight-color);
}

@media screen and (max-width: 500px) {
  #sort-buttons {
    justify-content: space-around;
  }
}
