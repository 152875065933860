.modal-container {
  z-index: 4;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.modal {
  height: 28rem;
  width: 24rem;
  /* background-color: var(--bg-color); */
  background-color: rgb(226, 226, 226);
  border-radius: 8px;
}

#logout-btn {
  position: absolute;
  bottom: 2rem;
  left: 50%;
  transform: translateX(-50%);
  font: inherit;
  font-weight: 700;
  height: 2rem;
  width: 8rem;
  background-color: rgb(187, 192, 192);
  border: none;
  border-radius: 6px;
  color: black;
  cursor: pointer;
}

#logout-btn:hover {
  box-shadow: 0px 3px 8px #888888;
}

#user-email {
  position: absolute;
  top: 2.8rem;
  left: 50%;
  transform: translateX(-50%);
}

.userDetails {
  height: 10rem;
}

#close-modal {
  position: absolute;
  right: 0;
  padding: 0.2rem;
  margin: 0.8rem;
  border-radius: 50%;
  cursor: pointer;
  background-color: rgb(187, 192, 192);
}

#close-modal:hover {
  box-shadow: 0px 2px 8px #888888;
}

.input-field {
  font: inherit;
  font-size: 0.9rem;
  border: 1px solid #444444;
  border-radius: 4px;
  height: 2.2rem;
  width: 96%;
  margin: 0.5rem 0 0.5rem 0;
  padding-left: 8px;
}

#login-btn {
  font: inherit;
  font-weight: 700;
  height: 2.2rem;
  width: 100%;
  background-color: rgb(187, 192, 192);
  border: none;
  border-radius: 6px;
  color: black;
  cursor: pointer;
  margin-top: 1.5rem;
}

#login-btn:hover {
  box-shadow: 0px 3px 8px #888888;
}

#signin-google {
  display: flex;
  height: 2.2rem;
  align-items: center;
  justify-content: center;
  width: 80%;
  border-radius: 6px;
  background-color: rgb(187, 192, 192);
  color: black;
  font-weight: 800;
  margin: 1rem 0 0 0;
  cursor: pointer;
}

#signin-google:hover {
  box-shadow: 0px 3px 8px #6e7387;
}

#signin-google img {
  height: 1rem;
  position: absolute;
  left: 3.5rem;
  margin: 0 0 0 1.2rem;
}

.redirect-modal {
  position: absolute;
  bottom: 2rem;
  cursor: pointer;
  margin-left: 5px;
  font-weight: 800;
}

.redirect-modal:hover {
  color: blue;
  border-bottom: 1.5px solid blue;
}

.modal-heading {
  margin: 2.5rem 0 4rem 0;
}

.error-alert {
  position: absolute;
  top: 5.2rem;
  background-color: #fbdad9;
  color: #775050;
  width: 77%;
  padding: 0.5rem 0.3rem 0.5rem 0.3rem;
  border: 2px solid #e9cbcc;
  border-radius: 6px;
}

.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

form {
  width: 80%;
}
