.header--container {
  position: fixed;
  height: 5rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--font-color);
  background-color: var(--bg-color);
  z-index: 2;
}

.sideDrawer-icon {
  margin-left: 1.2rem;
  margin-top: 0.2rem;
  position: absolute;
  cursor: pointer;
  display: none;
}

#logo {
  font-weight: 700;
  font-size: 1.8rem;
  padding-left: 3rem;
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

#theme-toggle img {
  height: 2rem;
  cursor: pointer;
}

#login-logout img {
  height: 2rem;
  margin: 0 3rem 0 1.8rem;
  cursor: pointer;
}

.search-bar {
  height: 2rem;
  width: 30vw;
  background-color: var(--highlight-color);
  border-radius: 6px;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.search-bar form {
  width: calc(100% - 2.8rem);
}
input:focus {
  outline: none;
}
.search-bar input {
  height: 1.8rem;
  width: 100%;
  border: none;
  padding-left: 0.5rem;
  background-color: var(--highlight-color);
}

.search-bar img {
  padding: 5px;
  cursor: pointer;
}

.mob-search-bar {
  position: absolute;
  /* display: none; */
  visibility: hidden;
  top: 4.3rem;
  left: 50%;
  transform: translate(-50%);
  height: 2rem;
  width: 90%;
  background-color: var(--highlight-color);
  border-radius: 6px;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 2;
}
.mob-search-bar form {
  width: calc(100% - 2.8rem);
}

.mob-search-bar input {
  height: 1.8rem;
  width: 100%;
  border: none;
  padding-left: 0.5rem;
  background-color: var(--highlight-color);
}

.mob-search-bar img {
  padding: 5px;
  cursor: pointer;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 3;
  background: rgba(0, 0, 0, 0.75);
}

@media screen and (max-width: 950px) {
  .sideDrawer-icon {
    display: block;
    color: var(--icon-color);
  }

  #logo {
    padding-left: 3.5rem;
  }

  .search-bar {
    display: none;
  }
  .mob-search-bar {
    /* display: block; */
    visibility: visible;
  }

  #login-logout img {
    height: 2rem;
    margin: 0 1.5rem 0 1.8rem;
    cursor: pointer;
  }
}
