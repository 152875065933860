.pages {
  position: absolute;
  height: calc(100vh - 5rem);
  /* height: 100vh; */
  width: calc(100% - 15rem);
  top: 5rem;
  left: 15rem;
  background-color: var(--bg-color);
}

.login-to-continue {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media screen and (max-width: 950px) {
  .pages {
    position: absolute;
    width: 100%;
    height: calc(100vh - 7rem);
    left: 0;
    z-index: 1;
    top: 7rem;
  }
}
